import React, { useState, useEffect, useCallback } from 'react';
import useToken from 'hooks/useToken';
import useUserService from 'services/user';
import useAuthorization from 'hooks/useAuthorization';

const UserCredentialsContext = React.createContext({});

export const UserCredentialsProvider = ({ children }) => {
  const [userCredentials, setUserCredentials] = useState(null);
  const [fetchState, setFetchState] = useState('');
  const { getUserCredentials } = useUserService();
  const { userAuthorization } = useAuthorization();
  const token = useToken();

  const loading = fetchState === 'loading';
  const error = fetchState === 'error';
  const success = fetchState === 'success';

  const handleError = useCallback((e) => {
    console.error(e.message, '.Error trying to fetch Credentials');
    setFetchState('error');
  }, []);

  const handleResponse = useCallback((response) => {
    const { data } = response;

    setUserCredentials(data ? [...data].pop() : null);
    setFetchState('success');
  }, []);

  useEffect(() => {
    let cleanUp = false;
    const fetchUserCredentials = async () => {
      setFetchState('loading');
      try {
        handleResponse(await getUserCredentials());
      } catch (e) {
        handleError(e);
      }
    };

    if (
      token &&
      !userCredentials &&
      userAuthorization &&
      fetchState === 'initial' &&
      !cleanUp
    ) {
      fetchUserCredentials();
    }
    return () => (cleanUp = true);
  }, [
    token,
    userAuthorization,
    userCredentials,
    fetchState,
    getUserCredentials,
    handleError,
    handleResponse,
  ]);

  const loadUserCredentials = () => {
    setFetchState('initial');
  };

  const defaultValue = {
    userCredentials,
    loadUserCredentials,
    loading,
    error,
    success,
  };

  return (
    <UserCredentialsContext.Provider value={defaultValue}>
      {children}
    </UserCredentialsContext.Provider>
  );
};

export default UserCredentialsContext;
