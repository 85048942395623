import React from 'react';

const HealthIcon = (props) => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8.65411 1.2615L7.99766 1.91938L7.33918 1.2609C5.66024 -0.418038 2.93814 -0.418038 1.2592 1.2609C-0.419735 2.93984 -0.419735 5.66194 1.2592 7.34088L7.57431 13.656C7.80859 13.8903 8.18841 13.8903 8.42268 13.656L14.7427 7.33971C16.4179 5.6552 16.4207 2.94074 14.7415 1.2615C13.0595 -0.4205 10.3361 -0.420497 8.65411 1.2615ZM13.892 6.49369L7.9985 12.3834L2.10757 6.49251C0.897175 5.28211 0.897175 3.31967 2.10757 2.10927C3.31797 0.898872 5.28041 0.898872 6.49081 2.10927L7.57656 3.19502C7.81481 3.43327 8.20249 3.42863 8.43497 3.18474L9.50248 2.10987C10.7159 0.896412 12.6797 0.89641 13.8931 2.10987C15.1038 3.32056 15.1018 5.27718 13.892 6.49369Z" />
    </svg>
  );
};

export default HealthIcon;
