export const STATEMENT_EVENTS = {
  PIX_CASH_IN_ACCOUNT: 'PIX_CASH_IN_ACCOUNT',
  PIX_CASH_OUT_ACCOUNT: 'PIX_CASH_OUT_ACCOUNT',
  PIX_CASH_IN_REFUND_ACCOUNT: 'PIX_REFUND_ACCOUNT',
  PIX_CASH_OUT_REFUND_ACCOUNT: 'PIX_CASH_OUT_REFUND_ACCOUNT',
  CASH_OUT_ACCOUNT: 'CASH_OUT_ACCOUNT',
  CASH_IN_ACCOUNT: 'CASH_IN_ACCOUNT',
  REFUND_ACCOUNT: 'REFUND_ACCOUNT',
  PAYBILL: 'PAYBILL',
  TRANSACTION_HOLDING: 'TRANSACTION_HOLDING',
  TRANSACTION_VOUCHER: 'TRANSACTION_VOUCHER',
};

export const CHANNEL_TYPE = {
  INTERNAL: 'INTERNAL',
  SPI: 'SPI',
  SPB: 'SPB',
  BANKSLIP: 'BANKSLIP',
};

export const ACCOUNT_TYPE = {
  BANK: '1',
  AGENT: '2',
};

export const STATEMENT_ORDER = {
  LAST: -1,
  OLDER: 1,
};

export const STATEMENT_STATUS_TYPE = {
  ALL: undefined,
  ENTRIES: 'C',
  WITHDRAWALS: 'D',
};

export const STATEMENT_LATEST_DAYS = {
  LATEST_7_DAYS: 7,
  LATEST_15_DAYS: 15,
  LATEST_30_DAYS: 30,
  LATEST_60_DAYS: 60,
  LATEST_90_DAYS: 90,
};

export const STATEMENT_STATUS = {
  ALL: 'all',
  DEPOSIT: 'BANKSLIP',
  PIX: 'PIX',
  CARD: 'CARD',
  PAYMENTS: 'BILL',
  TED: 'TED',
  REFUND: 'REFUND',
  ORDER: 'order',
  ...STATEMENT_LATEST_DAYS,
  SPECEFIC_DATE: 'SPECEFIC_DATE',
  BEGIN_DATE_TIME: 'begin_date_time',
  END_DATE_TIME: 'end_date_time',
};

export const STATEMENT_STATUS_NAME = {
  BANKSLIP: 'Depósito',
  PIX: 'Pix',
  CARD: 'Cartão',
  BILL: 'Pagamentos',
  TED: 'TED',
  REFUND: 'Reembolso',
};

export const STATEMENT_SHEETS = Object.freeze({
  NONE: 'NONE',
  HERO_FILTER: 'HERO_FILTER',
  HERO_EXPORT_STATEMENT: 'HERO_EXPORT_STATEMENT',
  HERO_SEND_STATEMENT: 'HERO_SEND_STATEMENT',
});

export const STATEMENT_SHEETS_VIEWS = {
  MANUAL_PERIOD: 'MANUAL_PERIOD',
  HERO_FILTER: 'HERO_FILTER',
};

export const MODAL_SEND_STATEMENTS_STEPS = {
  CONFIG: 'CONFIG',
  LIST_RECEIPTS: 'LIST_RECEIPTS',
  DELETE: 'DELETE',

  REGISTER_FILTER: 'REGISTER_FILTER',
  REGISTER_EMAIL: 'REGISTER_EMAIL',
  REGISTER_FILE_TYPE: 'REGISTER_FILE_TYPE',
  REGISTER_REVIEW: 'REGISTER_REVIEW',
  REGISTER_DESCRIPTION: 'REGISTER_DESCRIPTION',

  UPDATE_FILTER: 'UPDATE_FILTER',
  UPDATE_EMAIL: 'UPDATE_EMAIL',
  UPDATE_FILE_TYPE: 'UPDATE_FILE_TYPE',
  UPDATE_REVIEW: 'UPDATE_REVIEW',
  UPDATE_DESCRIPTION: 'UPDATE_DESCRIPTION',
};

export const SCHEDULE_STEP_TYPES = {
  CONFIG: 'CONFIG',
  FILTER: 'FILTER',
  LIST_RECEIPTS: 'LIST_RECEIPTS',
  EMAIL: 'EMAIL',
  FILE_TYPE: 'FILE_TYPE',
  REVIEW: 'REVIEW',
  DESCRIPTION: 'DESCRIPTION',
  DELETE: 'DELETE',
};

export const PERIOD_RELEASES = [
  {
    label: 'Todos',
    value: STATEMENT_STATUS_TYPE.ALL,
  },
  {
    label: 'Entradas',
    value: STATEMENT_STATUS_TYPE.ENTRIES,
  },
  {
    label: 'Saídas',
    value: STATEMENT_STATUS_TYPE.WITHDRAWALS,
  },
];

export const PERIOD_OPTIONS = [
  {
    label: 'Últimos 7 dias',
    value: STATEMENT_STATUS.LATEST_7_DAYS,
  },
  {
    label: 'Últimos 15 dias',
    value: STATEMENT_STATUS.LATEST_15_DAYS,
  },
  {
    label: 'Últimos 30 dias',
    value: STATEMENT_STATUS.LATEST_30_DAYS,
  },
  {
    label: 'Últimos 60 dias',
    value: STATEMENT_STATUS.LATEST_60_DAYS,
  },
  {
    label: 'Últimos 90 dias',
    value: STATEMENT_STATUS.LATEST_90_DAYS,
  },
  {
    label: 'Data específica',
    value: STATEMENT_STATUS.SPECEFIC_DATE,
  },
];

export const PERIOD_DATE_OPTIONS = [
  {
    label: 'Semanal',
    value: 'WEEKLY',
  },
  {
    label: 'Quinzenal',
    value: 'BIWEEKLY',
  },
  {
    label: 'Mensal',
    value: 'MONTHLY',
  },
];

export const PERIOD_DATE_SUCCESS = {
  MONTHLY: 'mensalmente',
  WEEKLY: 'semanalmente',
  BIWEEKLY: 'quinzenalmente',
};

export const STATEMENT_PAGES = {
  PROCESSING: 'PROCESSING',
  SUCCESS_EXPORT_STATEMENT: 'SUCCESS_EXPORT_STATEMENT',
  ERROR_EXPORT_STATEMENT: 'ERROR_EXPORT_STATEMENT',
  ERROR_USERS_UNAUTHORIZED: 'USERS_UNAUTHORIZED',
  ERROR_EXPORT_UNAUTHORIZED: 'EXPORT_UNAUTHORIZED',
  ERROR_BALANCE_NOT_FOUND: 'BALANCE_NOT_FOUND',
  ERROR_USERS_UNKNOWN: 'USERS_UNKNOWN',
  ERROR_CREATE_SCHEDULE: 'ERROR_CREATE_SCHEDULE',
  ERROR_DELETE_RECEIPT: 'ERROR_DELETE_RECEIPT',
  ERROR_UPDATE_RECEIPT: 'ERROR_UPDATE_RECEIPT',
  SUCCESS_CREATE_SCHEDULE: 'SUCCESS_CREATE_SCHEDULE',
  SUCCESS_DELETE_RECEIPT: 'SUCCESS_DELETE_RECEIPT',
  SUCCESS_UPDATE_RECEIPT: 'SUCCESS_UPDATE_RECEIPT',
};

export const ROWS_PER_PAGE = 15;
export const MAX_INTERVAL_DATES = 90;

export const DEFAULT_HERO_FILTER = {
  period: null,
  order: null,
  operationType: [],
  date: null,
};

export const DEFAULT_PERIOD = PERIOD_OPTIONS[0];

export const STATEMENT_ACTIONS = {
  SET_PAGE_STATE: 'SET_PAGE_STATE',
  GO_DASHBOARD: 'GO_DASHBOARD',
  DELETE_SCHEDULE: 'DELETE_SCHEDULE',
  CREATE_SCHEDULE: 'CREATE_SCHEDULE',
  CREATE_SCHEDULE_TO_DASHBOARD: 'CREATE_SCHEDULE_TO_DASHBOARD',
  UPDATE_SCHEDULE: 'UPDATE_SCHEDULE',
};

export const SCHEDULE_ACTIONS = {
  GO_TO_STEP: 'GO_TO_STEP',
  PREVIOUS_STEP: 'PREVIOUS_STEP',
  DELETE_SCHEDULE: 'DELETE_SCHEDULE',
  EDIT_MODE: 'EDIT_MODE',
};

export const SCHEDULE_MODE = {
  REGISTER: 'REGISTER',
  EDIT: 'EDIT',
};

export const TIMELY_STATEMENT_DEFAULT_FILE_TYPE = ['CSV', 'OFX', 'PDF'];
