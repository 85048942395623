import React from 'react';
import { Switch, withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import PrivateRoute from 'Routes/components/PrivateRoute';
import PrivateLayouted from 'Routes/components/PrivateLayouted';

import { PATHS } from './constants';
import { TransitionContainer } from './transitions';

import Dashboard from 'pages/Dashboard';
import NotFound from 'pages/NotFound';

import { ClientsRoutes } from './Groups/clientsRoutes';
import { TechnologiesRoutes } from './Groups/technologiesRoutes';
import { BankRoutes } from './Groups/bankRoutes';
import { ConfigurationRoutes } from './Groups/configurationRoutes';
import { publicRoutes } from './Groups/publicRoutes';
import { ProfileRoutes } from './Groups/profileRoutes';
import { usePageViewAnalytics } from 'services/analytics';
import { AgentRoutes } from './Groups/agentRoutes';
import Components from 'pages/Components';

function Routes({ location }) {
  usePageViewAnalytics();

  return (
    <>
      <TransitionContainer>
        <TransitionGroup className="transition-group">
          <CSSTransition
            key={location.key}
            timeout={{ enter: 450, exit: 450 }}
            classNames={'wizard'}
          >
            <section className="route-section">
              <Switch location={location}>
                <PrivateLayouted
                  component={Dashboard}
                  exact
                  path="/radar-de-oportunidades"
                />
                {ClientsRoutes()}
                {TechnologiesRoutes()}
                {BankRoutes()}
                {ConfigurationRoutes()}
                {publicRoutes()}
                {ProfileRoutes()}
                {AgentRoutes()}
                <PrivateLayouted
                  component={Dashboard}
                  exact
                  path={PATHS.DASHBOARD}
                />
                <PrivateRoute path="/componentes" component={Components} />
                <PrivateRoute component={NotFound} />
              </Switch>
            </section>
          </CSSTransition>
        </TransitionGroup>
      </TransitionContainer>
    </>
  );
}

export default withRouter(Routes);
