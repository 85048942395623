const PATHS = {
  REGISTER: '/register',
  CALLBACK: '/callback',
  NOROUTE: 'nothing-here',
  ACCOUNT_CHART: '/plano-de-contas/:id',
  ACCOUNT: {
    PUBLIC: {
      CREATE: '/onboarding/nova-conta',
      DOCUMENTS: '/onboarding/nova-conta/documentos',
      WAITING_ANALYSIS: '/onboarding/aguardando-analise',
      BLOCKED: '/onboarding/bloqueado',
      REVIEW_DATA: '/onboarding/revisar-dados',
    },
    CREATE: '/nova-conta',
    CREATE_ADD_MEMBER_LIST: '/nova-conta/:id/representantes',
    CREATE_ADD_DOCUMENTS_LIST: '/nova-conta/:id/documentos',
    CREATE_ADD_DOCUMENT: '/nova-conta/:id/cadastrar-documento',
    CREATE_ADD_MEMBER: '/nova-conta/:id/cadastrar-representante/:member?',
    CREATE_ADD_REPRESENTER_DOCUMENT: '/nova-conta/:id/representante/documentos',
    CREATE_TO: '/nova-conta/:id',
    ACTIVATE: '/ativar-conta',
    SWITCH_BANK_ACCOUNTS: '/trocar-conta',
  },
  AGENT: {
    DASHBOARD: '/agente',
    SWITCH_AGENT_ACCOUNTS: '/trocar-agente',
    PROFILE: '/agente/perfil',
    CONFLICT: '/agente/conflito/:id',
    INVITE: '/agente/convite',
    CLIENT: {
      ADD_PF: '/agente/novo/cpf',
      ADD_CNPJ: '/agente/novo/cnpj',
    },
  },
  BACKUPS: '/backups/:id?',
  BALANCE_SHEET: '/balanco-patrimonial/:id',
  BALANCE: '/balancete/:id',
  BANK: {
    DASHBOARD: '/contbank',
    FULL_BALANCE: '/extrato',
    STATEMENT: '/extratoV2',
    OFX: '/extrato-ofx',
    INCOME_REPORT: '/informe-de-rendimentos',
  },
  PIX: {
    AREA: '/pix',
    KEYS: {
      LIST: '/chaves',
      REGISTER: '/chaves/nova',
    },
    TRANSFER: '/pix/transferir',
    TRANSFER_CODE: '/pix/transferir/:code?',
    TRANSFER_QRCODE: '/pix/transferir/qrCode',
  },
  BILLING: {
    CREATE: '/cobranca/nova-cobranca',
    MANAGEMENT: '/cobranca/gestao',
    CONFIG: '/cobranca/configuracao',
  },
  ONBOARDING: {
    PF: '/onboarding-v2/pf/:stepId?',
    PJ: '/onboarding-v2/pj/:stepId?',
    CORPORATION: '/onboarding-v2/corporation/:stepId?',
    INTRO: '/onboarding-v2/intro',
    REGISTRO: '/onboarding-v2/register/:stepId?',
    ACCOUNT: {
      PF: '/onboarding-v2/conta/pf/:stepId?',
      PJ: '/onboarding-v2/conta/pj/:stepId?',
    },
  },
  BANKSLIP: {
    SEND: '/emitir-boleto',
    SENDTO: '/emitir-boleto-para/:id',
    LIST: '/boletos',
    DETAILS: '/boleto/:id',
  },
  TRANSACTIONAL_PASSWORD: {
    ADD: '/cadastrar-senha',
    ADDTO: '/cadastrar-senha/:origin',
  },
  CANCEL_ACCOUNT: '/meu-perfil/cancelar',
  CLIENTS: {
    LIST: '/clientes',
    ADD: '/novo-cliente',
    VIEW: '/cliente/:id',
    EDIT: '/modificar-cliente/:id/:section',
    FILES: '/cliente/:id/documentos',
    NEW_FILE: '/cliente/:id/novo-documento',
    EDIT_FILE: '/cliente/:id/documento/:fileId',
    MEMBER: {
      INVITE: '/cliente/:id/:member/invite',
      FILES: '/cliente/:id/:member/documentos',
      EDIT: '/modificar-cliente/:id/:section/:index?',
      EDIT_FILE: '/modificar-cliente/:id/documento/:doctype/:member/:fileId?',
    },
  },
  CONFIGURATIONS: '/configuracoes',
  CONTRACTS: '/contratos',
  CUSTOMERS: '/clientes',
  DASHBOARD: '/',
  CONTACTS: {
    LIST: '/contatos',
    ADD: '/novo-contato',
    EDIT: '/modificar-contato/:id',
    DETAIL: '/contato/:id',
    ACCOUNT: {
      DETAIL: '/contato/:id/contas',
      ADD: '/contato/:id/nova-conta',
      EDIT: '/modificar-contato/:id/conta/:account_id',
    },
  },
  LOGIN: '/login',
  OPPORTUNITIES: '/oportunidades',
  PREFERENCES: '/configuracoes',
  PERMISSIONS: '/permissoes',
  PROFILE: '/meu-perfil',
  EDIT_PROFILE: '/editar-perfil',
  STATEMENTS: '/plano-de-contas/:clientid/transacoes/:id',
  TECHNOLOGIES: {
    LIST: '/sistemas-contabeis',
    ADD: '/novo-sistema-contabil',
    DETAILS: '/sistema-contabil/:id',
    EDIT: '/modificar-sistema-contabil/:id',
    LIST_NF: '/sistema-notas-fiscais',
    ADD_NF: '/novo-sistema-notas-fiscais',
    EDIT_NF: '/modificar-sistema-notas-fiscais/:id',
  },
  PRICELIST: {
    LIST: '/lista-de-precos',
    ADD: '/novo-servico',
    DETAILS: '/ver-servico/:id',
    ASSOCIATE: '/associar-servico/:id',
    EDIT: '/servico/:id',
  },
  PAYER: {
    LIST: '/pagadores',
    ADD: '/novo-pagador',
    DETAILS: '/pagador/:id',
  },
  PAYMENT: {
    PAY: '/pagar/:code?',
    PAY_MOBILE: '/pagar-mobile',
  },
  CARD: {
    MY_CARD: '/meu-cartao/id/:proxy',
    STATUS: '/meu-cartao/status',
    UNLOCK: '/meu-cartao/desbloqueio/:proxy',
    UNLOCK_WITH_CODE: '/meu-cartao/desbloqueio/:proxy/:code',
    UNLOCK_MOBILE: '/meu-cartao/desbloqueio-mobile/:code',
    PASSWORD: '/meu-cartao/cadastro-senha',
    REQUEST: '/meu-cartao/solicitar',
  },
  TRANSFER: {
    SEND: '/transferir',
    SENDTO: '/transferir/:id',
  },
  VISIBILITY: '/visibilidade',
  WIZARD_SINGLE: '/single/:step?',
  WIZARD: '/wiz/:step?',
  WAITVALIDATION: '/aguardando-validacao',
  EXTERNAL: {
    CREDIT:
      'https://contbank.com/antecipacao?utm_source=app&utm_campaign=credito_sidebar_app&utm_term=credito',
    HEALTH_PLAN: 'https://parceiro.saudebliss.com.br/contbank/formulario',
  },
  APP_LINK_REDIRECT: '/app',
};

export { PATHS };
