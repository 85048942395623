import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PATHS } from 'Routes/constants';
import { clearURL } from 'tools';
import PersonIcon from 'assets/images/PersonIcon';
import Configs from 'styles/variables';
import PasswordIcon from 'assets/images/PasswordIcon';
import CompanyIcon from 'assets/images/CompanyIcon';
import ContactIcon from 'assets/images/ContactIcon';
import DocumentIcon from 'assets/images/DocumentIcon';
import DashboardIcon from 'assets/images/DashboardIcon';
import CreditIcon from 'assets/images/CreditIcon';
import HealthIcon from 'assets/images/HealthIcon';

const useBankMenu = (hasTransactionToken, hasAccount) => {
  const { t } = useTranslation('menu');
  const menuOptions = useMemo(
    () =>
      [
        {
          id: 'a0',
          location: clearURL(PATHS.BANK.DASHBOARD),
          label: t('menu:new.start', 'Início'),
          icon: <DashboardIcon fill={Configs.newColors.n200} />,
        },
        ...[
          {
            id: 'a1',
            location: PATHS.ACCOUNT.CREATE,
            label: t('menu:new.open_account', 'Abrir Conta'),
            icon: <CompanyIcon fill={Configs.newColors.n200} />,
          },
        ],
        ...[
          {
            id: 'a2',
            location: PATHS.EXTERNAL.CREDIT,
            label: t('menu:new.credit', 'Crédito'),
            icon: <CreditIcon fill={Configs.newColors.n200} />,
            external: true,
          },
        ],
        ...[
          {
            id: 'a3',
            location: PATHS.EXTERNAL.HEALTH_PLAN,
            label: t('menu:new.health_plan', 'Plano de Saúde'),
            icon: <HealthIcon fill={Configs.newColors.n200} />,
            external: true,
          },
        ],

        ...[
          hasAccount && {
            id: 'a4',
            location: PATHS.CONTACTS.LIST,
            label: t('menu:submenus.contbank.contacts', 'Contatos'),
            icon: <ContactIcon fill={Configs.newColors.n200} />,
          },
        ],
        ...[
          hasAccount && {
            id: 'a5',
            location: PATHS.BANK.INCOME_REPORT,
            label: t('menu:new.income_report', 'Informe de Rendimentos'),
            icon: <DocumentIcon fill={Configs.newColors.n200} />,
          },
        ],
        {
          id: 'a6',
          location: clearURL(PATHS.PROFILE),
          label: t('menu:new.my_profile', 'Meu Perfil'),
          icon: <PersonIcon fill={Configs.newColors.n200} />,
        },

        ...[
          hasAccount &&
            !hasTransactionToken && {
              id: 'a7',
              location: PATHS.TRANSACTIONAL_PASSWORD.ADD,
              label: t('menu:new.password', 'Cadastrar Senha'),
              icon: <PasswordIcon fill={Configs.newColors.n200} />,
            },
        ],

        // ...[
        //   hasAccount && {
        //     id: 'a3',
        //     location: PATHS.TRANSACTIONAL_PASSWORD.ADD,
        //     label: t('menu:new.security', 'Segurança'),
        //     icon: <SecurityIcon fill={Configs.newColors.n200} />,
        //   },
        // ],
        // {
        //   id: 1,
        //   location: hasAccount ? PATHS.BANK.DASHBOARD : PATHS.ACCOUNT.CREATE,
        //   label: t('menu:contbank', 'Contbank'),
        //   icon: (),
        // },
        // {
        //   id: 3,
        //   location: PATHS.ACCOUNT.CREATE,
        //   label: t('menu:submenus.contbank.open_account', 'Abrir conta'),
        //   icon: (),

        // },
        // ...[
        //   hasAccount &&
        //     canDo('read:transactions', 'bank') === true && {
        //       id: 4,
        //       location:
        //         window.ReactNativeWebView && window.enableCamera
        //           ? PATHS.PAYMENT.PAY_MOBILE
        //           : PATHS.PAYMENT.PAY,
        //       label: t('menu:submenus.contbank.payments', 'Pagamentos'),
        //     },
        // ],
        // ...[
        //   hasAccount &&
        //     canDo('read:transactions', 'bank') === true && {
        //       id: 5,
        //       location: clearURL(PATHS.TRANSFER.SEND),
        //       label: t('menu:submenus.contbank.transfers', 'Transferências'),
        //     },
        // ],
        // ...[
        //   hasAccount && {
        //     id: 6,
        //     location: PATHS.CONTACTS.LIST,
        //     label: t('menu:submenus.contbank.contacts', 'Contatos'),
        //   },
        // ],
        // ...[
        //   hasAccount && {
        //     id: 7,
        //     location: PATHS.BANKSLIP.LIST,
        //     label: 'Boletos',
        //   },
        // ],
        // ...[
        //   hasAccount && {
        //     id: 8,
        //     location: PATHS.BANK.INCOME_REPORT,
        //     label: t(
        //       'menu:submenus.contbank.income_report',
        //       'Informe de rendimentos'
        //     ),
        //   },
        // ],
        // ...[
        //   hasAccount &&
        //     !hasTransactionToken && {
        //       id: '9.7',
        //       location: PATHS.TRANSACTIONAL_PASSWORD.ADD,
        //       label: 'Cadastrar Senha',
        //     },
        // ],
      ].filter((item) => typeof item === 'object'),
    [t, hasAccount, hasTransactionToken]
  );

  return {
    menuOptions,
  };
};

export default useBankMenu;
