import React from 'react';

import { Route, Switch } from 'react-router-dom';
import { PATHS } from 'Routes/constants';

import { UserCredentialsProvider } from 'contexts/user-credentials';

const ListPermissions = React.lazy(() => import('./List'));

const PermissionsPage = () => {
  return (
    <Switch>
      <Route path={PATHS.PERMISSIONS} exact>
        <UserCredentialsProvider>
          <ListPermissions />
        </UserCredentialsProvider>
      </Route>
    </Switch>
  );
};

export default PermissionsPage;
