import styled, { css } from 'styled-components';
import media from 'styles/media';
import Configs from 'styles/variables';
import InputMask from 'react-input-mask';

const CssDisabled = css`
  opacity: 0.3;
  cursor: not-allowed;
`;

export const FieldGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  position: relative;
`;

export const FieldLabel = styled.label`
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: ${Configs.darkGray};
  display: none;
  ${media.mobile`
    display: flex;
  `}
`;

export const FieldError = styled.div`
  font-family: 'Manrope';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: ${Configs.errorColor};
  display: flex;
  max-width: 80%;
  ${({ noTransform }) => !noTransform && `transform: translateY(-40px);`};
`;

const fieldStyle = (props) => {
  return css`
    border: none;
    border-bottom: solid 1px ${Configs.input.borderColor};
    padding: ${(props) => props.padding}px 0;
    font-size: ${(props) => props.size}px;
    background: transparent;
    color: ${Configs.black};
    font-weight: ${(props) => props.weight};
    font-style: normal;
    letter-spacing: -0.86px;
    text-align: center;
    max-width: 50%;
    &.non {
      border: solid 10px red !important;
    }
    &.has-error {
      border-bottom-color: ${Configs.errorColor};
    }
    ${media.mobile`
      max-width: calc(100% - 92px);
      font-size: 22px;
      line-height: 1.18;
      letter-spacing: normal;
      color: ${Configs.darkGray};
      text-align: left;
      padding: 8px 0;
    `}

    &:disabled {
      ${CssDisabled};
    }
  `;
};

export const Field = styled.input.attrs((props) => ({
  size: props.big ? 48 : 16,
  padding: props.big ? 13 : 8,
  weight: props.important ? 'bold' : 'normal',
}))`
  ${(props) => fieldStyle(props)}
`;

export const FieldMulti = styled.textarea.attrs((props) => ({
  size: props.big ? 48 : 16,
  padding: props.big ? 13 : 8,
  weight: props.important ? 'bold' : 'normal',
}))`
  ${(props) => fieldStyle(props)}
  resize: none;
  overflow: none;
  word-break: break-word;
`;

export const MaskedInput = styled(InputMask).attrs((props) => ({
  size: props.big ? 48 : 16,
  padding: props.big ? 13 : 8,
  weight: props.important ? 'bold' : 'normal',
}))`
  ${(props) => fieldStyle(props)}
`;

export const PwdControll = styled.div.attrs((props) => ({
  size: props.big ? 36 : 24,
}))`
  position: absolute;
  left: 75%;
  top: ${(props) => props.size}px;
  max-width: 50%;
  button {
    svg {
      width: ${(props) => props.size}px;
      height: ${(props) => props.size}px;
    }
  }
  &.has-error {
    border-bottom-color: ${Configs.errorColor};
  }
  ${media.mobile`
  ${(props) =>
    props.fullWidthMobile
      ? `
    left: auto;
    right: 10px;
  `
      : `
    left: calc(100% - 92px);
  `};
      transform: translateY(-${(props) => props.size}px);
      button {
        svg {
          width: 24px;
          height: 24px;
        }
      }
    `}
`;
