import React from 'react';
import { PATHS } from 'Routes/constants';
import PrivateLayouted from 'Routes/components/PrivateLayouted';
import Prices from 'pages/Prices';
import PrivateWizardSingle from 'Routes/components/PrivateWizardSingle';
import useCan from 'hooks/useCan';
import PermissionsPage from 'pages/Permissions';
import SwitchBankAgent from 'pages/SwitchBankAgent';

const AgentProfile = React.lazy(() => import('pages/AgentProfile'));
const AgentConflict = React.lazy(() => import('pages/AgentConflict'));
const Configurations = React.lazy(() => import('pages/Configurations'));
const Backups = React.lazy(() => import('pages/Backups'));
const Profile = React.lazy(() => import('pages/Profile'));

export const ConfigurationRoutes = () => {
  const { canDo } = useCan();

  return [
    <PrivateLayouted
      component={Configurations}
      exact
      path={PATHS.CONFIGURATIONS}
      key={PATHS.CONFIGURATIONS}
    />,
    <PrivateLayouted
      component={SwitchBankAgent}
      exact
      path={PATHS.AGENT.SWITCH_AGENT_ACCOUNTS}
      key={PATHS.AGENT.SWITCH_AGENT_ACCOUNTS}
    />,
    ...[
      canDo('read:backups', 'business') && (
        <PrivateLayouted
          component={Backups}
          exact
          path={PATHS.BACKUPS}
          key={PATHS.BACKUPS}
        />
      ),
    ],
    ...[
      canDo('read:catalogs') && (
        <PrivateLayouted
          component={Prices}
          exact
          path={PATHS.PRICELIST.LIST}
          key={PATHS.PRICELIST.LIST}
        />
      ),
    ],
    ...[
      canDo('read:catalogs') && (
        <PrivateWizardSingle
          component={Prices}
          exact
          path={PATHS.PRICELIST.ADD}
          key={PATHS.PRICELIST.ADD}
        />
      ),
    ],
    ...[
      canDo('read:catalogs') && (
        <PrivateWizardSingle
          component={Prices}
          exact
          path={PATHS.PRICELIST.ASSOCIATE}
          key={PATHS.PRICELIST.ASSOCIATE}
        />
      ),
    ],
    ...[
      canDo('read:catalogs') && (
        <PrivateLayouted
          component={Prices}
          exact
          path={PATHS.PRICELIST.DETAILS}
          key={PATHS.PRICELIST.DETAILS}
        />
      ),
    ],
    ...[
      canDo('read:catalogs') && (
        <PrivateWizardSingle
          component={Prices}
          exact
          path={PATHS.PRICELIST.EDIT}
          key={PATHS.PRICELIST.EDIT}
        />
      ),
    ],
    <PrivateLayouted
      component={Profile}
      exact
      path={PATHS.PROFILE}
      key={PATHS.PROFILE}
    />,
    <PrivateLayouted
      component={Profile}
      exact
      path={PATHS.CANCEL_ACCOUNT}
      key={PATHS.CANCEL_ACCOUNT}
    />,
    <PrivateLayouted
      component={AgentProfile}
      exact
      path={PATHS.AGENT.PROFILE}
      key={PATHS.AGENT.PROFILE}
    />,
    <PrivateWizardSingle
      component={AgentConflict}
      exact
      path={PATHS.AGENT.CONFLICT}
      key={PATHS.AGENT.CONFLICT}
    />,
    <PrivateLayouted
      component={PermissionsPage}
      exact
      path={PATHS.PERMISSIONS}
      key={PATHS.PERMISSIONS}
    />,
  ];
};
