import React, { useState, useEffect, useCallback, useContext } from 'react';
import useUserService from 'services/user';
import useToken from 'hooks/useToken';
import UserAuthorizationContext from './authorization';
import usePasswordService from 'services/password';
import { useMemo } from 'react';

const RegistrationsContext = React.createContext({
  registrations: false,
  setRegistrations: (arr) => {},
  loadRegistrations: () => {},
  hasSocialName: false,
  loading: false,
  error: false,
  success: false,
  hasTransactionToken: false,
  hasOldTransactionToken: false,
  loadingPassword: false,
  loadedPassword: false,
  loadedAndNoHasTransactionToken: false,
});

export const RegistrationsProvider = ({ children }) => {
  const [registrations, setRegistrations] = useState(false);
  const [hasSocialName, setHasSocialName] = useState(false);
  const [hasTransactionToken, setHasTransactionToken] = useState(false);
  const [hasOldTransactionToken, setHasOldTransactionToken] = useState(false);
  const { loading: loadingAuth, currentAccountId: selectedAccountId } =
    useContext(UserAuthorizationContext);

  const token = useToken();
  const { getRegister } = useUserService(token);
  const { hasPassword } = usePasswordService(token);
  const [fetchState, setFetchState] = useState('initial');
  const [fetchPasswordState, setPasswordFetchState] = useState('initial');

  const loading = fetchState === 'loading';
  const initial = fetchState === 'initial';
  const success = fetchState === 'success';
  const error = fetchState === 'error';
  const loadingPassword = fetchPasswordState === 'loading';
  const loadedPassword = fetchPasswordState === 'loaded';

  useEffect(() => {
    let mounted = true;

    const fetchPassword = async () => {
      setPasswordFetchState('loading');
      try {
        const passwordStatus = await hasPassword();
        const passwordResponse = passwordStatus.data;

        setHasTransactionToken(passwordResponse.status === 'CREATED');
        setHasOldTransactionToken(passwordResponse.status === 'DEPRECATED');
      } catch (e) {
        setHasTransactionToken(false);
        setHasOldTransactionToken(false);
      }
      setPasswordFetchState('loaded');
    };

    if (
      mounted &&
      !loadingAuth &&
      fetchPasswordState === 'initial' &&
      selectedAccountId
    ) {
      fetchPassword();
    }
    return () => (mounted = false);
  }, [
    loadingAuth,
    selectedAccountId,
    hasPassword,
    setPasswordFetchState,
    fetchPasswordState,
  ]);

  useEffect(() => {
    let mounted = true;
    const hasUserToken = token && !token.error;
    const fetchRegistrations = async () => {
      try {
        setFetchState('loading');
        const response = await getRegister(token);

        setHasSocialName(response.data && !!response.data.social_name);
        setRegistrations(response.data ? response.data : null);
        setFetchState('success');
      } catch (e) {
        setFetchState('error');
        console.error(e.message, '. Error trying to fetch registration info.');
        setRegistrations({
          error: true,
          message: e.message || 'generic error',
        });
      }
    };

    if (mounted && initial && hasUserToken) {
      fetchRegistrations();
    }
    return () => (mounted = false);
  }, [initial, token, getRegister]);

  const loadRegistrations = useCallback(() => {
    setFetchState('initial');
  }, []);

  const loadPassword = useCallback(() => {
    setPasswordFetchState('initial');
  }, []);

  const getOldTransactionToken = () => hasOldTransactionToken;

  const loadedAndNoHasTransactionToken = useMemo(
    () => loadedPassword && !hasTransactionToken,
    [loadedPassword, hasTransactionToken]
  );

  return (
    <RegistrationsContext.Provider
      value={{
        registrations,
        setRegistrations,
        loadRegistrations,
        hasSocialName,
        setHasSocialName,
        loading,
        error,
        success,
        hasTransactionToken,
        hasOldTransactionToken,
        getOldTransactionToken,
        loadPassword,
        loadingPassword,
        loadedPassword,
        loadedAndNoHasTransactionToken,
      }}
    >
      {children}
    </RegistrationsContext.Provider>
  );
};

export default RegistrationsContext;
